<template>
  <svg :class="svgClass" :style="svgStyle" :width="width" :height="height" v-on="$listeners">
    <use :xlink:href="iconName" />
  </svg>
</template>

<script>
export default {
  name: "SvgIcon",
  props: {
    iconClass: {
      type: String,
      requried: true
    },
    className: {
      type: String,
      default: ""
    },
    width: {
      type: String,
      default: ""
    },
    height: {
      type: String,
      default: ""
    }
  },
  computed: {
    iconName() {
      return `#icon-${this.iconClass}`
    },
    svgClass() {
      if (this.className) {
        return "svg-icon" + this.className
      } else {
        return "svg-icon"
      }
    },
    svgStyle() {
      return {
        width: this.width,
        height: this.height || this.width
      }
    }
  }
}
</script>

<style scoped lang="scss">
.svg-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  overflow: hidden;
  vertical-align: -.15em;
  fill: currentcolor;
}
</style>
