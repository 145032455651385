import axiosInstance from "./normalService"
import fileAxiosInstance from "./fileService"
import { Message } from "element-ui"
import qs from "qs"

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get (url, params) {
  return new Promise((resolve, reject) => {
    axiosInstance.get(url, {
      params: params,
      paramsSerializer: params => {
        return qs.stringify(params, { indices: false })
      }
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        setTimeout(() => {
          Message({
            message: err.message,
            type: "error"
          })
        }, 0)
        reject(err)
      })
  })
}
/**
* post方法，对应post请求
* @param {String} url [请求的url地址]
* @param {Object} params [请求时携带的参数]
*/
export function post (url, params) {
  return new Promise((resolve, reject) => {
    axiosInstance.post(url, params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        setTimeout(() => {
          Message({
            message: err.message,
            type: "error"
          })
        }, 0)
        reject(err)
      })
  })
}
/**
* put方法，对应put请求
* @param {String} url [请求的url地址]
* @param {Object} params [请求时携带的参数]
*/
export function put (url, params) {
  return new Promise((resolve, reject) => {
    axiosInstance.put(url, params)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        Message({
          message: err.message,
          type: "error"
        })
        reject(err)
      })
  })
}
/**
* del方法，对应delete请求
* @param {String} url [请求的url地址]
* @param {Object} params [请求时携带的参数]
*/
export function del (url, params) {
  return new Promise((resolve, reject) => {
    axiosInstance.delete(url, {
      params: params
    })

      .then(res => {
        resolve(res)
      })
      .catch(err => {
        Message({
          message: err.message,
          type: "error"
        })
        reject(err)
      })
  })
}
/**
 * export方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function exportFileAxios (url, params) {
  return new Promise((resolve, reject) => {
    fileAxiosInstance.get(url, {
      params: params,
      responseType: "blob"
    })
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}
