import Vue from "vue"
import VueRouter from "vue-router"
// 线上环境打开 - 路由权限判断
import setupRouterGuard from "./permission.js"

Vue.use(VueRouter)

const routeOptions = [{
  path: "/password-login",
  name: "PasswordLogin",
  parentName: "1-login"
},
// {
//   path: "/verification-code-login",
//   name: "VerificationCodeLogin",
//   parentName: "1-login"
// },
// {
//   path: "/password-setting",
//   name: "PasswordSetting",
//   parentName: "1-login"
// },
{
  path: "/company-selection",
  name: "CompanySelection",
  parentName: "1-login"
},
{
  path: "/password-forgetting",
  name: "PasswordForgetting",
  parentName: "1-login"
},
{
  path: "/",
  name: "Index",
  parentName: "2-home",
  children: [
    {
      path: "/",
      name: "Home",
      parentName: "2-home"
    },
    {
      path: "/personal-information",
      name: "PersonalInformation",
      parentName: "2-home"
    },
    {
      path: "/pay-management",
      name: "PayManagement",
      parentName: "3-cost"
    },
    {
      path: "/pay-refund-record",
      name: "PayRefundRecord",
      parentName: "3-cost"
    },
    {
      path: "/refund-management",
      name: "RefundManagement",
      parentName: "3-cost"
    },
    {
      path: "/short-message-record",
      name: "ShortMessageRecord",
      parentName: "3-cost"
    },
    {
      path: "/gate-control",
      name: "GateControl",
      parentName: "4-control"
    },
    {
      path: "/power-protection",
      name: "PowerProtection",
      parentName: "4-control"
    },
    {
      path: "/reading-record",
      name: "ReadingRecord",
      parentName: "4-control"
    },
    {
      path: "/abnormal-event",
      name: "AbnormalEvent",
      parentName: "5-abnormal"
    },
    {
      path: "/alarm-rules",
      name: "AlarmRules",
      parentName: "5-abnormal"
    },
    {
      path: "/cost-rules",
      name: "CostRules",
      parentName: "5-abnormal"
    },
    {
      path: "/push-rules",
      name: "PushRules",
      parentName: "5-abnormal"
    },
    {
      path: "/energy-analysis",
      name: "EnergyAnalysis",
      parentName: "6-report-form"
    },
    {
      path: "/payment-statistics",
      name: "PaymentStatistics",
      parentName: "6-report-form"
    },
    {
      path: "/fee-schedule",
      name: "FeeSchedule",
      parentName: "6-report-form"
    },
    {
      path: "/payment-classification",
      name: "PaymentClassification",
      parentName: "6-report-form"
    },
    {
      path: "/reading-settlement-report",
      name: "ReadingSettlementReport",
      parentName: "6-report-form"
    },
    {
      path: "/build-management",
      name: "BuildManagement",
      parentName: "7-files"
    },
    {
      path: "/electricity-meter",
      name: "ElectricityMeter",
      parentName: "7-files"
    },
    {
      path: "/electricity-price",
      name: "ElectricityPrice",
      parentName: "7-files"
    },
    {
      path: "/Electricity-price-distribution",
      name: "ElectricityPriceDistribution",
      parentName: "7-files"
    },
    {
      path: "/tenant-management",
      name: "TenantManagement",
      parentName: "7-files"
    },
    {
      path: "/multi-rate-time-frame-config",
      name: "RateTimeConfig",
      parentName: "7-files"
    },
    {
      path: "/time-frame-issue-record",
      name: "PeriodDistribution",
      parentName: "7-files"
    },
    {
      path: "/role-management",
      name: "RoleManagement",
      parentName: "8-system"
    },
    {
      path: "/user-management",
      name: "UserManagement",
      parentName: "8-system"
    },
    {
      path: "/account-setting",
      name: "AccountSetting",
      parentName: "8-system"
    },
    {
      path: "/operate-log",
      name: "OptLog",
      parentName: "8-system"
    }
  ]
}
]

const routes = routeOptions.map((route) => {
  if (route.children) {
    const children = []
    route.children.map((child) => {
      if (!child.component) {
        child = {
          ...child,
          component: () =>
            import(
              /* webpackChunkName: "[request]" */
              `../views/${child.parentName}/${child.name}.vue`
            )
        }
      }
      children.push(child)
    })
    route.children = children
  }
  if (!route.component) {
    route = {
      ...route,
      component: () =>
        import(
          /* webpackChunkName: "[request]" */
          `../views/${route.parentName}/${route.name}.vue`
        )
    }
  }
  return route
})

const router = new VueRouter({
  routes
})

// 线上环境打开 - 路由权限判断
setupRouterGuard(router)

export default router
