import { createService, setReqInterceptors, requestCache } from "./axios"
import { handleRequestCache, RequestError } from "@/utils/request-deduplication"

const service = createService()
// 设置请求拦截
setReqInterceptors(service, requestCache)

// 文件管理接口响应拦截
service.interceptors.response.use(
  response => {
    // 如果请求返回，对应的requestCache.isResponse设置为true
    handleRequestCache(requestCache, response.config)
    return response.data
  },
  error => {
    if (error instanceof RequestError) {
      throw error
    } else {
      // 如果请求返回，对应的requestCache.isResponse设置为true
      handleRequestCache(requestCache, error.config)

      const { status } = error.response
      let message
      switch (status) {
        case 500:
          message = "服务器连接失败"
          break
        case 401:
          message = "登录过期，请重新登录"
          break
        default:
          message = `系统异常，请刷新页面或检查网络连接(错误码:${status})`
      }

      return Promise.reject(new Error(message))
    }
  }
)

export default service
