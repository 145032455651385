<template>
  <div id="app" :style="{ height: height + 'px' }">
    <router-view />
  </div>
</template>
<script>
export default {
  data () {
    return {
      height: window.innerHeight
    }
  },
  mounted () {
    const that = this
    window.onresize = () => {
      return (() => {
        that.height = window.innerHeight
      })()
    }
  }
}
</script>

<style lang="scss">
#app {
  // font-family: 'Microsoft YaHei', Arial, Helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}
</style>
